import _moment from "moment";
import firebase from 'firebase/app';
import 'firebase/firestore';

const moduleEvents = {
	state: () => ({
		events: [],
		isLoadingEvents: false,
	}),

	mutations: {
		mutationIsLoadingCalendarEvents(state, isLoading) {
			console.log(`Mutation - Module Calendar - mutationIsLoadingCalendarEvents`)
			state.isLoadingEvents = isLoading
		},

		mutationStoreEvents(state, events) {
			console.log(`Mutation - Module Calendar - MutationStoreEvents`)
			state.events = events
		},
	},

    actions: {
		async actionGetEventsForCurrentYear({ commit, state }, data) {
			console.log(`Action - Module Calendar - actionGetEventsForCurrentYear`)

			let events = []

			await
				firebase
					.firestore()
					.collection('users')
					.doc(data.uid)
					.collection('events')
					.doc(data.year.toString())
					.collection('walks')
					.get()
					.then(querySnapshot => {
						querySnapshot.forEach(doc => {
							events.push({
								collectionid: doc.id,
								...doc.data(),
								eventDate: _moment.unix(doc.data().eventDate.seconds).toDate(),
								startTime: _moment.unix(doc.data().startTime.seconds).toDate(),
								endTime: _moment.unix(doc.data().endTime.seconds).toDate()
							})

						})
					})
					.catch((err) => { console.log(err) })

			await
				firebase
					.firestore()
					.collection('users')
					.doc(data.uid)
					.collection('events')
					.doc(data.year.toString())
					.collection('boarding')
					.get()
					.then(querySnapshot => {
						querySnapshot.forEach(doc => {
							events.push({
								collectionid: doc.id,
								...doc.data(),
								eventDate: _moment.unix(doc.data().eventDate.seconds).toDate(),
								startTime: _moment.unix(doc.data().startTime.seconds).toDate(),
								endTime: _moment.unix(doc.data().endTime.seconds).toDate()
							})
						})
					})
					.catch((err) => { console.log(err) })

			await
				firebase
					.firestore()
					.collection('users')
					.doc(data.uid)
					.collection('events')
					.doc(data.year.toString())
					.collection('expense')
					.get()
					.then(querySnapshot => {
						querySnapshot.forEach(doc => {
							
							events.push({
								collectionid: doc.id,
								...doc.data(),
								eventDate: _moment.unix(doc.data().eventDate.seconds).toDate(),
								startTime: _moment.unix(doc.data().startTime.seconds).toDate(),
							})
						})
					})
					.catch((err) => { console.log(err) })

			await
				firebase
					.firestore()
					.collection('users')
					.doc(data.uid)
					.collection('events')
					.doc(data.year.toString())
					.collection('daycare')
					.get()
					.then(querySnapshot => {
						querySnapshot.forEach(doc => {
							events.push({
								collectionid: doc.id,
								...doc.data(),
								eventDate: _moment.unix(doc.data().eventDate.seconds).toDate(),
								startTime: _moment.unix(doc.data().startTime.seconds).toDate(),
								endTime: _moment.unix(doc.data().endTime.seconds).toDate()
							})
						})
					})
					.catch((err) => { console.log(err) })

			await
				firebase
					.firestore()
					.collection('users')
					.doc(data.uid)
					.collection('events')
					.doc(data.year.toString())
					.collection('custom')
					.get()
					.then(querySnapshot => {
						querySnapshot.forEach(doc => {

							let convertedTimes = []

							doc.data().times.forEach(time => {
								
								if(typeof time.startTime === 'string') {
									convertedTimes.push({
										startTime: _moment(time.startTime).toDate(),  
										endTime: _moment(time.endTime).toDate()
									})	
								} else {
									convertedTimes.push({
										startTime: _moment.unix(time.startTime.seconds).toDate(),  
										endTime: _moment.unix(time.endTime.seconds).toDate()
									})	
								}
							
							})

							events.push({
								collectionid: doc.id,
								...doc.data(),
								eventDate: _moment.unix(doc.data().eventDate.seconds).toDate(),
								startTime: _moment.unix(doc.data().startTime.seconds).toDate(),
								endTime: _moment.unix(doc.data().endTime.seconds).toDate(),
								times: convertedTimes

							
							})
						})
					})
					.catch((err) => { console.log(err) })  

			return events
		},
    },

	getters: {
		getterGetCurrentEvents: state => {
			return state.events
		},

	}
}

export default moduleEvents