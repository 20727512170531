import firebase from 'firebase/app';
import 'firebase/firestore';

const SUBSCRIPTION_STATUS = {
    // https://stripe.com/docs/api/subscriptions/object
    CODES: {
        active: 'active',
        past_due: 'past_due',
        unpaid: 'unpaid',
        canceled: 'canceled',
        incomplete: 'incomplete',
        incomplete_expired: 'incomplete_expired'
    },

    modalConfig: {
        title: "Renewal failure",
        message: "There was an issue processing payment for your subscription renewal. We will attempt to take the payment automatically within the next two weeks and if in that event payment fails your subscription will be automatically canceled.",
        okButtonText: "close"
    },

    warning_msg: 'There was an issue with your recent subscription renewal, Tap for more information.',
    
}

const moduleUser = {
	state: () => ({
		isUserAuthenticated: false,
		isLoadingSub: true,
		isLoadingInvoices: true,
		auth: {
			uid: '',
			email: '',
			displayName: '',
			photoURL: '',
			emailVerified: '',
		},

		subscription: null,
	
	}),

	mutations: {
		mutationStoreUser(state, user)	 {
			console.log(`Mutation - Module user - mutationStoreUser`)
			state.auth = {
				...user
			}
		},

		mutationUpdateEmailAddress(state, email)	 {
			console.log(`Mutation - Module user - mutationUpdateEmailAddress`)
			state.auth.email = email
		},

		mutationLoadingSub(state, status)	 {
			console.log(`Mutation - Module user - mutationLoadingSub`)
			state.isLoadingSub = status
		},

		mutationStoreSub(state, sub)	 {
			console.log(`Mutation - Module user - mutationStoreSub`)
			state.auth.subscription = sub
		},

		mutationAuthenticatedStatus(state, status)	 {
			console.log(`Mutation - Module user - mutationAuthenticatedStatus`)
			state.isUserAuthenticated = status
		},

		mutationResetUserState(state) {
			console.log(`Mutation - Module user - mutationResetUserState`)
			state.auth = null,
			state.invoices = []
			state.isUserAuthenticated = false
			state.subscription = null
		},

		mutationLoadingInvoices(state, status) {
			console.log(`Mutation - Module user - mutationLoadingInvoices`)
			state.isLoadingInvoices = status
		},

		mutationStoreInvoices(state, invoices) {
			console.log(`Mutation - Module user - mutationStoreInvoices`)
			state.invoices = invoices
		},

		mutationFilterSubscriptions(state, subs) {
			console.log('Mutation - Module user - mutationFilterSubscriptions')

			let subscriptions = subs
			let activeSubscriptions = []
			let nonActiveSubscriptions = []

			// Find any active subscriptions
			activeSubscriptions = subscriptions.filter(item => item.status == SUBSCRIPTION_STATUS.CODES.active)

			// Find any non active ie unpaid or past_due
			nonActiveSubscriptions = subscriptions.filter(item => item.status == SUBSCRIPTION_STATUS.CODES.past_due || item.status == SUBSCRIPTION_STATUS.CODES.unpaid)
			
			if(activeSubscriptions.length == 0) {
				// Look for any unpaid or past_due subscriptions
				// TODO provide feedback to user if they have more than one sub
				nonActiveSubscriptions = nonActiveSubscriptions.reduce((a, b) => {
					return new Date(a.created) > new Date(b.created) ? a : b;
				});

				state.subscription = nonActiveSubscriptions

			} else if(activeSubscriptions.length > 0) { 
				// what if there is more than one subscription?
				// TODO provide feedback to user if they have more than one sub
				activeSubscriptions = activeSubscriptions.reduce((a, b) => {
					return new Date(a.created) > new Date(b.created) ? a : b;
				});

				state.subscription = activeSubscriptions
				
			}
		},
	},

	actions: {
		async actionGetUserSubscription({ commit, state, rootGetters }, data) {
			console.log(`Action - Module user - actionGetUserSubscription`)
			let subscriptions = []
			
			await firebase.firestore()
            .collection("users")
            .doc(data.uid)
            .collection('subscriptions')
			.where('status', 'in', ['active', 'past_due', 'unpaid', 'incomplete', 'canceled'])
            .get()

            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    subscriptions.push({
						collectionid: doc.id,
						...doc.data()
					})
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

			return subscriptions
		},

		async actionGetMonthlyInvoices({ commit, state, rootGetters }, data) {
			console.log(`Action - Module user - actionGetMonthlyInvoices`)
			let invoices = []
			
			await firebase.firestore()
            .collection("users")
            .doc(data.uid)
            .collection('subscriptions')
			.doc(data.subscription)
			.collection('invoices')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    invoices.push({
						stripeUrl: doc.data().hosted_invoice_url,
						start: doc.data().period_start
					})
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

			return invoices
		}
	},

	getters: {
		getterUser: state => {
			return state.auth
		},

		getterIsAuthenticated: state => {
			return state.isUserAuthenticated
		},

		getterIsLoadingUserSub: state => {
			return state.isLoadingSub
		},

		getterIsLoadingInvoices: state => {
			return state.isLoadingInvoices
		},

		getterInvoices: state => {
			return state.invoices
		},

		subscription(state) {
			return state.subscription
		}
	}
}

export default moduleUser