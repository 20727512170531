import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import moduleDogs from './store-dogs'
import moduleUser from './store-user'
import moduleEvents from './store-events'
import moduleGroups from './store-groups'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user: moduleUser,
        dogs: moduleDogs,
        events: moduleEvents,
        groups: moduleGroups

    },

    plugins: [createPersistedState()],
})
