<template>
<ui-wrapper full  :image="require('@/assets/images/fullscreen-contact.jpeg')">
  <ui-row
    large
    spaceEvenly
    stackMobile
    full
  >
    <ui-col
      offsetSmall
      full
    >
      <ui-h white>Get in touch</ui-h>
      
      <div class="svg">
        <web fill="white" /> 
        <a href="mailto: info@diggdawg.com">info@diggdawg.com</a>
      </div>

      <div class="svg">
        <fb fill="white" /> 
        <a target="_blank" href="https://www.facebook.com/diggdawg">@DiggDawg</a>
      </div>

      <div class="svg">
        <ig fill="white" /> 
        <a target="_blank" href="https://www.instagram.com/diggdawg_/">@DiggDawg</a>
      </div>
    </ui-col>

  </ui-row>
</ui-wrapper>
</template>

<script>
import web from '@/assets/svgs/embed2.svg';
import fb from '@/assets/svgs/facebook2.svg';
import ig from '@/assets/svgs/instagram.svg';

export default {
    name: 'Home',

    components: {
      web,
      fb,
      ig
    }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
}

.svg {
  display: flex;
  align-items: center;
  margin: rem(20) 0;

  svg {
    height: 42px;
    width: 42px;
  }

  a {
    color: $white;
    display: inline-block;
    margin-left: rem(10);
    font-size: rem(36);
    text-decoration: none;

    @media(max-width: $mobile-bp) {
        font-size: rem(28);
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
