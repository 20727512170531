<template>
    <ui-wrapper image='' overlay>
        <ui-row large spaceEvenly stackMobile full fullWidth> 
			<ui-col half mobile>
				<div class="feature feature--dogs">
					<ui-h>The dog house</ui-h>
					<ui-p>Individual profiles for your dogs storing all the important information in one place</ui-p>
					<img class="feature__image" :src="require('@/assets/images/features/ClientProfile.png')" alt="">
				</div>
			</ui-col>

			<ui-col half mobile>
				<div class="feature feature--groups">
					<ui-h>Organise the pack</ui-h>
					<ui-p>Keep track of all of your dogs and the relationships they have with each other</ui-p>
					<img class="feature__image" :src="require('@/assets/images/features/Groups.png')" alt="">
				</div>
			</ui-col>
		</ui-row>

		<ui-row large spaceEvenly stackMobile full fullWidth> 
			<ui-col half mobile>
				<div class="feature feature--diary">
					<ui-h>Forget pen and paper</ui-h>
					<ui-p>An easy to use calendar with a variety of events makes managing the pack a walk in the park</ui-p>
					<img class="feature__image" :src="require('@/assets/images/features/Calendar.png')" alt="">
				</div>
			</ui-col>

			<ui-col half mobile>
				<div class="feature feature--dashboard">
					<ui-h>Manage the day to day</ui-h>
					<ui-p>Forget the diary DiggDawg will make sure you've not missed one</ui-p>
					<img class="feature__image" :src="require('@/assets/images/features/Dashboard.png')" alt="">
				</div>
			</ui-col>
		</ui-row>

		<ui-row large spaceEvenly stackMobile full fullWidth> 
			<ui-col half mobile>
				<div class="feature feature--invoice">
					<ui-h>Send invoices</ui-h>
					<ui-p>Create invoice PDF's in a matter of moments and send straight to your clients</ui-p>
					<img class="feature__image" :src="require('@/assets/images/features/Invoicing.png')" alt="">
				</div>
			</ui-col>

			<ui-col half mobile>
				<div class="feature feature--finance">
					<ui-h>Balance the books</ui-h>
					<ui-p>Get simple and easy to read breakdowns of your incomings and outgoings</ui-p>
					<img class="feature__image" :src="require('@/assets/images/features/Finance.png')" alt="">
				</div>
			</ui-col>
		</ui-row>


		<ui-row large spaceEvenly stackMobile full fullWidth> 
			<ui-col half mobile>
				<div class="feature feature--birthday">
					<ui-h>Never miss a birthday</ui-h>
					<ui-p>We'll keep you posted on the birthday celebrations so you never miss out</ui-p>
					<img class="feature__image" :src="require('@/assets/images/features/Birthdays.png')" alt="">
				</div>
			</ui-col>

			<ui-col half mobile>
				<div class="feature feature--documents">
					<ui-h>Documents store</ui-h>
					<ui-p>Upload and send all of your documents at the touch of a button</ui-p>
					<img class="feature__image" :src="require('@/assets/images/features/Documents.png')" alt="">
				</div>
			</ui-col>
		</ui-row>
    </ui-wrapper>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
    components: {
        Carousel,
        Slide,
    },
};
</script>

<style lang="scss" >
.feature {
	padding: rem(40);
	margin: rem(20) 0;
	color: #ffffff;
	text-align: center;

	&--dogs {
		background: #3399FF;
	}

	&--groups {
		background: #FA9917;
	}

	&--diary {
		background: #2AC940;
	}

	&--events {
		background: #FF5F58;
	}

	&--invoice {
		background: #673AB7;
	}

	&--finance {
		background: #4034CA;
	}

	&__header {
		color: #ffffff;
	}

	&--birthday {
		background: #FF4081;
	}

	&--documents {
		background: #00B8D4;
	}

	&--dashboard {
		background: #DD2C00;
	}

	&__image {
		max-width: 300px;
		width: 100%;
	}
}
</style>