<template>
    <div id="app">
        <div class="wrapper">
           <div class="wrapper__content">
			    <h1>DiggDawg has a new home</h1>
            <p>
                Please click the following button to be taken to the new
                website.
            </p>

			<ui-button secondary v-on:tapped="goToNewWebsite">www.diggdawg.io</ui-button>
		   </div>
        </div>
    </div>
</template>

<script>
export default {
	methods: {
		goToNewWebsite() {
			window.open('https://www.diggdawg.io/')
		}
	}
}
</script>

<style lang="scss">
@import "./scss/main";
#app,
body,
html {
    width: 100%;
	height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    background-color: $primary;
    color: $white;
	text-align: center;
}

.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
</style>
