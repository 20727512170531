import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import firebase from '../firestoreInit'

Vue.use(VueRouter)

const routes = [
    {
        path: "*",
        component: () => import('../views/404.vue'),
        name: '404',
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/features',
        name: 'Features',
        component: () => import('../views/Features.vue')
    },
    {
        path: '/cost',
        name: 'Cost',
        component: () => import('../views/Cost.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },

    {
        path: '/terms',
        name: 'terms',
        component: () => import('../views/Terms-And-Conditions.vue')
    },


    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/Privacy-And-Cookies.vue')
    },

    {
        path: '/account/dashboard',
        component: () => import('../views/Auth-Dashboard.vue'),
        meta: {
            requiresAuth: true
        }
    
    },

    {
        path: '/account',
        name: 'Account',
        component: () => import('../views/Auth-Account.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'subscription',
                component: () => import('../views/Auth-Subscription.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'subscriptionSuccess',
                component: () => import('../views/Auth-Subscription-Success.vue'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: 'details',
                component: () => import('../views/Auth-Details.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'logout',
                component: () => import('../views/Auth-Logout.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'help',
                component: () => import('../views/Auth-Help.vue'),
                meta: {
                    requiresAuth: true
                }
            },
        ]
    }
]

const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth && !await firebase.getCurrentUser()) {
        next('login');
    } else {
        next();
    }
})

export default router
