import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueCarousel from 'vue-carousel';
import VuePageTransition from 'vue-page-transition'
import { ValidationProvider } from 'vee-validate';
import * as moment from 'moment'
import VModal from 'vue-js-modal'
import Toasted from 'vue-toasted';
import VueYouTubeEmbed from 'vue-youtube-embed'
import VCalendar from 'v-calendar';


Vue.use(VueYouTubeEmbed)
Vue.use(Toasted, {
  duration: '10000'
})
Vue.use(VuePageTransition)
Vue.use(VueCarousel);
Vue.use(VModal, {
  dynamicDefaults: {
    draggable: true,
    resizable: true,
    height: 'auto'
  }
})

Vue.config.productionTip = false

/**
 * Components
 */
Vue.component('ui-container', () => import('@/components/ui-container.vue'));
Vue.component('ui-row', () => import('@/components/ui-row.vue'));
Vue.component('ui-col', () => import('@/components/ui-col.vue'));
Vue.component('ui-header', () => import('@/components/ui-header.vue'));
Vue.component('ui-p', () => import('@/components/ui-p.vue'));
Vue.component('ui-h', () => import('@/components/ui-h.vue'));
Vue.component('ui-image', () => import('@/components/ui-image.vue'));
Vue.component('ui-wrapper', () => import('@/components/ui-wrapper.vue'));
Vue.component('ui-button', () => import('@/components/ui-button.vue'));
Vue.component('ui-avatar', () => import('@/components/ui-avatar.vue'));
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VCalendar);


Vue.prototype.moment = moment
new Vue({

  router,
  store,
  render: h => h(App)
}).$mount('#app')
