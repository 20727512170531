<template>
    <ui-wrapper image='' overlay>

		<ui-row>
			<ui-col
				full
			>
				<ui-h black center>Take the tour</ui-h>
			</ui-col>
		</ui-row>

        <ui-row large spaceEvenly stackMobile full fullWidth rowWrap> 
			<ui-col
				small 
				mobile
				v-for="video in videos"
				:key="video.id"
			>
				<div class="video-container">
					<ui-h color="#673AB7" small>{{video.title}} </ui-h>
					<youtube :video-id="video.id" player-width="100%" />
				</div>
			</ui-col>



			
		</ui-row>
    </ui-wrapper>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
	data() {
		return {
			videos: [
				{
					id: 'EMKvS1K7JuM',
					title: '1. Creating dog/client profiles'
				},

				{
					id: '8BK7T5uXlLM',
					title: '2. Groups and relationships'
				},

				{
					id: 'tI_nkm4fXhc',
					title: '3. Calendar events part I'
				},

					{
					id: 'MFEZWT4eKTs',
					title: '4. Calendar events part II'
				},

				{
					id: 'TFcrXDCFNQc',
					title: '5. Copy week'
				},

				{
					id: 'D1ok_sgGjq8',
					title: '6. Invoicing'
				},

				{
					id: 'vaKjJRhJ5Fk',
					title: "7. Who's paid"
				},

				{
					id: 'jvsjqfRJu2w',
					title: '8. Admin area'
				},

			]
		}
	},

    components: {
        Carousel,
        Slide,
    },
};
</script>

<style lang="scss" >
.video-container {
	margin: 10px 0;
}
</style>