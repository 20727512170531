<template>
    <div>
        <ui-wrapper :image="require('@/assets/images/fullscreen-home.jpeg')" half mobile>
            <ui-row full stackMobile spaceEvenly >
                <ui-col half offsetSmall>
                    <ui-h>A pocket diary for dog care professionals.</ui-h>
                    <ui-p>
                        DiggDawg is a mobile app designed to help ease the burden of
                        managing your dog care business.
                    </ui-p>

                      <ui-row>
                        <ui-col>
                            <ui-p>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=org.nativescript.diggdawg">
                                    <ui-image
                                        :src="
                                            require('@/assets/images/google-play-badge.png')
                                        "
                                    />
                                </a>
                            </ui-p>
                        </ui-col>

                        <ui-col>
                            <ui-image
                                :src="
                                    require('@/assets/images/apple-store-badge.png')
                                "
                            />
                        </ui-col>
                    </ui-row>

                    
                </ui-col>

                <ui-col half mobile>
                    <ui-p center>
                        <youtube video-id="EMKvS1K7JuM" player-width="100%" />
                    </ui-p>
                </ui-col>
            </ui-row>
        </ui-wrapper>

        <view-features />
        <view-cost />
        <view-walkthrough />
        <view-contact />
    </div>
</template>

<script>
import viewFeatures from './Features.vue'
import viewCost from './Cost.vue'
import viewContact from './Contact.vue'
import viewWalkthrough from './Walkthrough.vue'

export default {
    name: "Home",

    components: {
        viewFeatures,
        viewCost,
        viewContact,
        viewWalkthrough
    },

    mounted() {
        
    }
};
</script>

<style lang="scss" scoped>
.home {
    height: 100%;
    width: 100%;
}
</style>
