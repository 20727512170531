import firebase from 'firebase/app';
import 'firebase/firestore';
import _moment from "moment";

const moduleGroups = {
	state: () => ({
        groups: [],
        isLoadingGroups: false
	}),

	mutations: {
        /**
		 * Async loader
		 */
		mutationIsLoadingGroups(state, status) {
			console.log('Mutation - Module Groups - mutationIsLoadingGroups')
			state.isLoadingGroups = status
		},

        /**
		 * Stores a users Groups
		 */
		mutationGetGroups(state, data) {
			console.log('Mutation - Module Groups - mutationGetGroups')
			state.groups = data
		},
	},

    actions: {
        /**
		 * Gets a logged in users Groups
		 */
		async actionStoreUserGroups({ commit, state }, uid) {
			let groups = []

			return await firebase
				.firestore()
				.collection('users')
				.doc(uid)
				.collection('groups')
				.get()
				.then(querySnapshot => {
					querySnapshot.forEach(doc => {
						let data = {  
							collectionid: doc.id,
							...doc.data(),
							selected: false,
							names: [],
							startTime: _moment.unix(doc.data().startTime.seconds).toDate(),
							endTime: _moment.unix(doc.data().endTime.seconds).toDate(),
						}
						groups.push(data)
					});
					return groups
				})
		},
    },

	getters: {
	
	}
}

export default moduleGroups