import firebase from 'firebase/app';
import 'firebase/firestore';
import _moment from "moment";

const moduleDogs = {
    state: () => ({
        dogs: [],
        isLoadingDogs: false,
    }),

    mutations: {

        /**
         * Async loader  
         */
        mutationIsLoadingDogs(state, status) {
            console.log('Mutation - Module dog - mutationIsLoadingDogs')
            state.isLoadingDogs = status
        },

        /**
         * Stores a users dogs
         */
        mutationGetDogs(state, data) {
            console.log('Mutation - Module dog - getDogs')
            state.dogs = data.dogs
        },
    },

    actions: {

        /**
         * Stores users dogs on either login or if a user is already logged in
         */
        async actionStoreUserDogs({ commit, state }, uid) {
            console.log('Action - Module user - actionStoreUserDogs')
            let dogs = []

            return await
                firebase
                    .firestore()
                    .collection('users')
                    .doc(uid)
                    .collection('dogs')
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {

                            let dogDOB = doc.data().dob

                            if (doc.data().dob instanceof Object) {
                                dogDOB = _moment.unix(doc.data().dob.seconds).toDate()
                            }

                            let data = {
                                collectionid: doc.id,
                                ...doc.data(),
                                selected: false,
                                walks: [],
                                initial: doc.data().name.charAt(0),
                                dob: dogDOB,
                            }

                            dogs.push(data)
                        });

                        return dogs
                    })

        },
    },

    getters: {

        getterDogSortDogs: state => { 
			let dogs = []

			dogs = state.dogs.sort((a, b) => {
				let nameA = a.name.toUpperCase(); 
				let nameB = b.name.toUpperCase(); 
				if (nameA < nameB) {
				  return -1;
				}
				if (nameA > nameB) {
				  return 1;
				}
				return 0; 
			  });

			  dogs = dogs.filter((dog) => {
				return dog.isPublished == true
			})

			return dogs
		},

        /** 
		 * TODO a bug here so we need to define name otherwise the tab groups 
		 * will throw an error when first creating an account
		 */
		getterDogProfile: (state) => (collectiondid) => {
			let dog = {
				name: '',
			};

			if(state.dogs.length > 0 ){
				dog = state.dogs.find(dog => dog.collectionid === collectiondid)
			} else {
				dog = {
					name: ''
				}
			}

			return dog
			
		},
    }
}

export default moduleDogs