<template>
<ui-wrapper
  :image="require('@/assets/images/fullscreen-cost.jpeg')"
  full
>
  <ui-row
    full
    stackMobile
    spaceEvenly
  >
    <ui-col
      offsetSmall
    >
      <ui-h>A montly affordable subscription</ui-h>
      <ui-p>
        Constant updates, feature requests, cancel at anytime, no incessant push notifications and no bs.
      </ui-p>
      <ui-p>
        Use discount code FIRSTMONTHFREE for a free month when subscribing.
      </ui-p>
    </ui-col>

    <ui-col
      offsetSmall
    >
      <div class="rounded">
        <div class="rounded__inner">
          <h2 class="rounded__header">£7.99</h2>
          <p class="rounded__copy">per month</p>
        </div>
      </div>

    </ui-col>
  </ui-row>
</ui-wrapper>
</template>

<script>
export default {
    name: 'Home'
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
}

.rounded {
  width: 350px;
  height: 350px;
  background: $primary;
  color: $white;
  border-radius: 50%;
  display: flex;
  border: 5px solid $white;
  margin: 0px auto;

  @media(max-width: $mobile-bp) {
    width: 100%;
    height: 300px;
  }

  &__inner {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    align-self: center;
  }

  &__header {
    font-size: rem(60);
    color: $white;
    text-align: center;
    margin: 0;
  }

  &__copy {
    font-size: rem(36);
    margin: 0;
    text-align: center;
    color: $white;
  }
}
</style>
